import React, { ReactElement } from 'react';
import unified from 'unified';
import { Node } from 'unist';

import { graphql } from 'gatsby';
import Layout from '../components/layout';

import RehypeReact from 'rehype-react';

import IntroSection from '../components/section-wines';

import { pageInfoByLocale, updateSEO } from '../queries/page-info-helpers';
import { getLocalizedValue } from '../components/locale-context';
import HTMLDiv from '../components/htmldiv';

import { PageSEO } from '../libs/seo';
import { LocationInfo } from '../libs/location';
import { PageInfoData } from '../queries/fragment-page-info';

const renderAst = (ast: Node, wines101html: string): JSX.Element => {
  // @ts-expect-error some weird RehypeReact typing issue
  const astProcessor = unified().use(RehypeReact, {
    createElement: React.createElement,
    components: {
      wines101: () => <Wines101 htmlContent = { wines101html } />
    }
  });
  return (astProcessor.stringify(ast) as unknown) as JSX.Element
}

interface Wines101Props {
  htmlContent: string
}

function Wines101 ({ htmlContent }: Wines101Props): ReactElement {
  return <HTMLDiv __html = { htmlContent } />;
}

interface WinesProps {
  data: PageWinesData,
  pageContext: { slug: string, locale: string},
  location: LocationInfo
}

export default function Wines ({ data, pageContext, location }: WinesProps): ReactElement {
  const { locale } = pageContext;
  const seo = PageSEO.fromLocation(location);
  const localPageInfo = getLocalizedValue(pageInfoByLocale(data.page), locale);
  updateSEO(seo, localPageInfo);

  const { md, w101 } = data;
  return (
    <Layout locale = { locale } location={location} title = { localPageInfo.title } seo = { seo }>
      <IntroSection />
      <div className = "w3-margin">
        { renderAst(md.htmlAst, w101.html) }
      </div>
    </Layout>
  );
}

interface PageWinesData {
  md: {
    htmlAst: Node 
  },
  w101: {
    html: string
  },
  page: PageInfoData
}

export const query = graphql`
query($locale: String!) {
  md: markdownRemark(fields: { slug: { regex: "/wines\\.[a-z]{2}/"}, locale: {eq: $locale} }) {
        htmlAst
  }
  w101: markdownRemark(fields: { slug: { regex: "/wines-101\\.[a-z]{2}/"}, locale: {eq: $locale} }) {
        html
  }
  page: pageIndexYaml(yamlId : {eq: "wines"}) {
    ...PageInfo
  }
}
`;
